/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProfileResponseDto { 
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    userType: ProfileResponseDto.UserTypeEnum;
    referralCode: string;
    donorStatus: ProfileResponseDto.DonorStatusEnum;
    creationTs: string;
}
export namespace ProfileResponseDto {
    export type UserTypeEnum = 'NEW' | 'DONOR';
    export const UserTypeEnum = {
        New: 'NEW' as UserTypeEnum,
        Donor: 'DONOR' as UserTypeEnum
    };
    export type DonorStatusEnum = 'ACTIVE' | 'ONBOARD_REQUIRED';
    export const DonorStatusEnum = {
        Active: 'ACTIVE' as DonorStatusEnum,
        OnboardRequired: 'ONBOARD_REQUIRED' as DonorStatusEnum
    };
}


